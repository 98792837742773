import React from 'react';
import CookieConsent from 'react-cookie-consent';
import content from '../../content/cookieNotification.yaml';

const CookieNotification = () => {
  const bannerStyle =
    'position: fixed bottom-0 z-20 flex flex-col items-start text-white w-full bg-gray-800 lg:items-stretch';

  return (
    <div data-testid="cookieNotification-cointainer" className={bannerStyle}>
      <CookieConsent
        buttonClasses="md:mt-4 mt-4.2 bg-secondary text-white border-opacity-0 pointer-fine:hover:border-opacity-100 pointer-fine:hover:bg-white pointer-fine:hover:text-black min-w-11 px-25px py-3 text-sm font-bold rounded tracking-tight border border-black transition-all duration-300 lg:mt-0"
        containerClasses="max-w-max flex flex-col items-start text-sm m-4 md:mx-7.4 md:text-base lg:flex-row lg:mx-68px lg:items-center lg:justify-between 2xl:mx-auto"
        buttonText={content.cta.dismiss}
        disableButtonStyles
        disableStyles
        expires={1} // cookie expires in 1 day
      >
        <div>
          <div className="font-normal text-sm leading-5 md:w-9/12">
            <div>
              {content.header} {content.description.line1}
            </div>
            <div className="mt-4.2">
              {content.description.line2}{' '}
              <a
                href="/cookies"
                className="underline decoration-white font-bold"
              >
                {content.cta.policy}
              </a>
            </div>
          </div>
        </div>
      </CookieConsent>
    </div>
  );
};

export default CookieNotification;
