import React, { useContext, useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';

import classNames from 'classnames';
import ContactButton from './ContactButton';
import calculateGradientColor from '../../utils/calculateGradientColor';
import { DrawerContext } from '../../context/DrawerContext';
import DrawerMenu from './DrawerMenu';
import globalStrings from '../../config/index';
import EnsembleLogo from './EnsembleLogo';
import HamburgerIcon from './HamburgerIcon';

type BodyPropsType = {
  isHeroInView: boolean;
  heroHeight: number;
  pageId: string;
};

const NavBar = ({
  isHeroInView: isHeaderInView,
  heroHeight,
  pageId = '',
}: BodyPropsType) => {
  const { isOpen, setIsOpen } = useContext(DrawerContext);
  const [y, setY] = useState(0);

  let gradientRatio = 1;
  if (y < heroHeight) {
    const scrollRatio = y / heroHeight;
    gradientRatio = scrollRatio ** 2;
  }

  const { logoAlt, home, services, portfolio, company, join, contact } =
    globalStrings.navigation;

  const textLinkClass = `mt-1 tracking-tight text-center`;

  const textLinkDynamicStyle = {
    color: calculateGradientColor('#000000', '#FFFFFF', gradientRatio),
  };

  const navbarContainerClass = classNames(
    'z-10',
    'inset-0',
    'md-900px:h-8',
    'h-7',
    'fixed',
  );

  const navbarClass = classNames(
    'h-full',
    'md-900px:px-68px',
    'px-4',
    'flex',
    'items-center',
    'justify-between',
    { 'bg-white': !isHeaderInView },
  );
  const mobileActiveClass = 'text-underline-secondary';
  const desktopActiveClass = 'border-underline-secondary';

  useEffect(() => {
    const handleNavigation = (e: Event) => {
      const window = e.currentTarget as Window;
      setY(window.scrollY);
    };
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [y]);

  return (
    <div className="z-10">
      <DrawerMenu isOpen={isOpen} closeMenu={() => setIsOpen(false)}>
        <Link
          key="home"
          to="/"
          activeClassName={mobileActiveClass}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {home}
        </Link>
        <Link
          key="services"
          to="/services"
          activeClassName={mobileActiveClass}
          partiallyActive
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {services}
        </Link>
        <Link
          key="portfolio"
          to="/portfolio"
          activeClassName={mobileActiveClass}
          partiallyActive
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {portfolio}
        </Link>
        <Link
          key="company"
          to="/company"
          activeClassName={mobileActiveClass}
          partiallyActive
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {company}
        </Link>
        <Link
          key="join"
          to="/join"
          activeClassName={mobileActiveClass}
          partiallyActive
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {join}
        </Link>
        <Link
          key="contact"
          to="/contact"
          activeClassName={mobileActiveClass}
          partiallyActive
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {contact}
        </Link>
      </DrawerMenu>
      {/* div to fade hero */}
      <div
        className="absolute w-full h-screen pointer-events-none"
        style={{
          height: `${heroHeight}px`,
          backgroundColor: `rgba(255,255,255,${gradientRatio})`,
        }}
      />
      {/* div to add shadow */}
      <div
        className={`shadow-md ${navbarContainerClass}`}
        style={{ opacity: gradientRatio }}
      />
      <div className={navbarContainerClass}>
        <div className={navbarClass}>
          <div className="flex items-center">
            <button
              className="md-900px:hidden mt-0.5 mr-4 flex items-center"
              onClick={() => setIsOpen(true)}
            >
              <HamburgerIcon gradientRatio={gradientRatio} pageId={pageId} />
            </button>
            <Link className="mr-6 flex items-center flex-shrink-0" to="/">
              <EnsembleLogo
                gradientRatio={gradientRatio}
                alt={logoAlt}
                pageId={pageId}
              />
            </Link>
            <div className="hidden md-900px:flex items-center space-x-6">
              <Link
                style={textLinkDynamicStyle}
                className={textLinkClass}
                to="/services"
                activeClassName={desktopActiveClass}
                partiallyActive
              >
                {services}
              </Link>
              <Link
                style={textLinkDynamicStyle}
                className={textLinkClass}
                to="/portfolio"
                activeClassName={desktopActiveClass}
                partiallyActive
              >
                {portfolio}
              </Link>
              <Link
                style={textLinkDynamicStyle}
                className={textLinkClass}
                to="/company"
                activeClassName={desktopActiveClass}
                partiallyActive
              >
                {company}
              </Link>
              <Link
                style={textLinkDynamicStyle}
                className={textLinkClass}
                to="/join"
                activeClassName={desktopActiveClass}
                partiallyActive
              >
                {join}
              </Link>
            </div>
          </div>
          <ContactButton
            gradientRatio={gradientRatio}
            pageId={pageId}
            onClick={event => {
              event.preventDefault();
              navigate('/contact');
              setIsOpen(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
