import React from 'react';
import EnsembleImage from '@assets/navbar/ensemble-logo.svg';
import theme from '../../config/theme.json';
import calculateGradientColor from '../../utils/calculateGradientColor';

type BodyPropsType = { gradientRatio: number; alt: string; pageId: string };

const primary = theme.colors.primaryColor;

const EnsembleLogo = ({ gradientRatio, alt, pageId = '' }: BodyPropsType) => {
  const gradient =
    pageId === 'company-hero-container'
      ? primary
      : calculateGradientColor(primary, 'FFFFFF', gradientRatio);
  return (
    <svg
      style={{ color: gradient }}
      className="fill-current"
      width="120"
      height="20"
      viewBox="0 0 120 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{alt}</title>
      <defs>
        <image
          id="image_ensemble_logo"
          width="1160"
          height="190"
          href={EnsembleImage}
        />
        <pattern
          id="pattern_ensemble_logo"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image_ensemble_logo"
            transform="scale(0.000862069 0.00526316)"
          />
        </pattern>
        <mask id="ensemble_logo-mask">
          <rect
            width="120"
            height="19.6552"
            fill="url(#pattern_ensemble_logo)"
          />
        </mask>
      </defs>
      <rect width="120" height="19.6552" mask="url(#ensemble_logo-mask)" />
    </svg>
  );
};

export default EnsembleLogo;
