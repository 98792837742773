import React, { cloneElement, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import Footer from './Footer';
import NavBar from './navbar/NavBar';
import CookieNotification from './cookieNotification/CookieNotification';

type BodyPropsType = {
  children: JSX.Element | null;
};

const Layout = ({ children = null }: BodyPropsType) => {
  const { ref: viewRef, inView, entry } = useInView({ threshold: 0 });
  const [heroHeight, setHeroHeight] = useState(0);
  const [pageId, setPageId] = useState('');

  useEffect(() => {
    setHeroHeight(entry?.target.scrollHeight || 0);
    setPageId(entry?.target.id || '');
  }, [entry, viewRef]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    const handleResize = () => {
      timeout = setTimeout(() => {
        setHeroHeight(entry?.target.scrollHeight || 0);
      }, 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeout);
    };
  });

  const childWithProps = children ? cloneElement(children, { viewRef }) : null;

  return (
    <div
      data-testid="layout-container"
      className="flex flex-col overflow-x-hidden lg:overflow-x-visible"
    >
      <NavBar isHeroInView={inView} heroHeight={heroHeight} pageId={pageId} />
      <CookieNotification />
      <main>{childWithProps}</main>
      <Footer />
    </div>
  );
};

export default Layout;
