import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import globalStrings from '../../config/index';

type BodyPropsType = {
  onClick?: MouseEventHandler;
  gradientRatio: number;
  pageId: string;
};

const ContactButton = ({
  onClick,
  gradientRatio,
  pageId = '',
}: BodyPropsType) => {
  const { contact } = globalStrings.navigation;

  const buttonConstRatio =
    pageId === 'company-hero-container' ? 1 : gradientRatio;
  const buttonClass = classNames(
    'py-1',
    'rounded-sm',
    'text-16px',
    'font-semibold',
    'tracking-tight',
    'w-140px',
    'h-36px',
    'pointer-fine:hover:transition-all',
    'duration-300',
    'pointer-fine:hover:bg-secondary',
  );
  const blueButtonClass = classNames('bg-primary', 'text-white', 'absolute');
  const whiteButtonClass = classNames(
    'bg-white',
    'text-regalBlue',
    'pointer-fine:hover:text-white',
  );

  return (
    <div className="grid grid-cols-1 invisible xs:visible">
      <button
        style={{
          opacity: 1 - buttonConstRatio,
          zIndex: 1 - buttonConstRatio,
        }}
        className={classNames(buttonClass, whiteButtonClass)}
        onClick={onClick}
      >
        {contact}
      </button>
      <button
        style={{
          opacity: buttonConstRatio,
          zIndex: buttonConstRatio,
        }}
        className={classNames(buttonClass, blueButtonClass)}
        onClick={onClick}
      >
        {contact}
      </button>
    </div>
  );
};

export default ContactButton;
