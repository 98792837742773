import React from 'react';
import theme from '../../config/theme.json';
import calculateGradientColor from '../../utils/calculateGradientColor';

type BodyPropsType = { gradientRatio: number; pageId: string };

const primary = theme.colors.primaryColor;

const HamburgerIcon = ({ gradientRatio, pageId = '' }: BodyPropsType) => {
  const gradient =
    pageId === 'company-hero-container'
      ? primary
      : calculateGradientColor(primary, 'FFFFFF', gradientRatio);
  return (
    <svg
      style={{ color: gradient }}
      className="fill-current stroke-current"
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      stroke="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="1"
        x2="17"
        y2="1"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="1"
        y1="7"
        x2="17"
        y2="7"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="1"
        y1="13"
        x2="17"
        y2="13"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HamburgerIcon;
