import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import ensembleLogo from '@assets/footer/ensemble-logo-white.svg';
import linkedinLogo from '@assets/footer/linkedin-logo-white.svg';
import footerBackgroundPng from '@assets/footer/footer-background.png';
import footerBackgroundWebp from '@assets/footer/footer-background.webp';
import globalStrings from '../config/index';
import Image from './Image';

const dividerClass =
  'md:pb-0 pb-4 md:border-b-0 border-b border-white border-opacity-20';
const textClass = 'text-white text-xs md:text-sm';

const Contact = () => {
  const { contact } = globalStrings.footer;

  return (
    <div className={`md:order-last space-y-4 ${dividerClass}`}>
      <div className={`font-bold ${textClass}`}>{contact.header}</div>
      <ul className="space-y-4">
        <li
          data-testid="contact-element"
          className={classNames(textClass, 'flex flex-row')}
        >
          <div className="font-semibold">{contact.main.label}&nbsp;</div>
          <div className="font-normal">{contact.main.number}</div>
        </li>
        <li
          data-testid="contact-element"
          className={classNames(textClass, 'flex flex-row')}
        >
          <div className="font-semibold">{contact.fax.label}&nbsp;</div>
          <div className="font-normal">{contact.fax.number}</div>
        </li>
      </ul>
    </div>
  );
};
const Location = () => {
  const { locations } = globalStrings.footer;
  return (
    <div className={`space-y-4 md:mt-0 mt-4 ${dividerClass}`}>
      <div className={`font-bold ${textClass}`}>{locations.header}</div>
      <ul className="space-y-4">
        {locations.locations.map((location: string) => (
          <li
            data-testid="location-element"
            key={location}
            className={textClass}
          >
            {location}
          </li>
        ))}
      </ul>
    </div>
  );
};
const Join = () => {
  const { join } = globalStrings.footer;
  return (
    <div
      data-testid="join-container"
      className={` space-y-4 md:mt-0 mt-4 ${dividerClass}`}
    >
      <div className={`font-bold ${textClass}`}>{join.header}</div>
      <div className={textClass}>{join.description}</div>
      <Link className={`block underline ${textClass}`} to="/join">
        {join.cta}
      </Link>
    </div>
  );
};
const Links = () => {
  const { privacy, cookies, terms } = globalStrings.footer;

  return (
    <div className="md:flex justify-end">
      <ul className="space-y-4 md:mt-0 mt-4 md:text-right">
        <li data-testid="link-element">
          <Link className={`${textClass}`} to="/privacy">
            {privacy}
          </Link>
        </li>
        <li data-testid="link-element">
          <Link className={textClass} to="/cookies">
            {cookies}
          </Link>
        </li>
        <li data-testid="link-element">
          <Link className={textClass} to="/termsofuse">
            {terms}
          </Link>
        </li>
      </ul>
    </div>
  );
};
const SocialMedia = () => {
  const { linkedin } = globalStrings.footer;
  return (
    <ul className="flex justify-center">
      <li data-testid="social-media-element" className="inline-block">
        <a href={linkedin.url} target="__blank">
          <img
            className="mt-1 w-44px h-36px"
            src={linkedinLogo}
            alt={linkedin.alt}
          />
        </a>
      </li>
    </ul>
  );
};
const Logo = () => {
  const { logoAlt } = globalStrings.footer;
  return (
    <div
      data-testid="logo-container"
      className="md:flex-grow md:mx-0 mx-auto md:mb-0"
    >
      <img src={ensembleLogo} alt={logoAlt} />
    </div>
  );
};

const Copyrights = () => {
  const { copyright } = globalStrings.footer;
  return (
    <div data-testid="copyright-container" className="space-y-2">
      <div className="text-white md:text-right text-center text-10px md:text-sm">
        {copyright.one.replace('{year}', `${new Date().getFullYear()}`)}
      </div>
      <div className="text-white md:text-right text-center text-10px md:text-sm">
        {copyright.two}
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="relative w-full h-full z-0">
      <Image
        src={footerBackgroundPng}
        webpSrc={footerBackgroundWebp}
        alt="footer"
        className="absolute w-full h-full object-cover object-left md:object-top"
      />
      <div className="flex justify-center">
        <div className="md:flex flex-col md:space-y-7 md:py-9 px-5 md:px-64px py-6 max-w-max z-10">
          <div className="md:grid grid-cols-2">
            <div className="md:grid grid-cols-2">
              <Contact />
              <Location />
            </div>
            <div className="md:grid grid-cols-2 justify-between">
              <Join />
              <Links />
            </div>
          </div>
          <div className="md:grid grid-cols-4 md:mt-7 mt-6 flex flex-col content-center ">
            <div className="mt-6 items-center flex flex-col grid-cols-2 md:self-end md:grid md:mt-auto md:h-full md:pt-2">
              <Logo />
            </div>
            <div className="flex justify-center order-first md:order-none  md:flex md:justify-self-start">
              <SocialMedia />
            </div>
            <div className="hidden md:block" />
            <div className="mt-3 md:mt-auto">
              <Copyrights />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
