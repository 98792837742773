const calculateGradientColor = (
  hexColorFrom: string,
  hexColorTo: string,
  gradientRatio: number,
) => {
  const toHex = (value: number) => {
    const hex = value.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  const colorFrom = hexColorFrom.startsWith('#')
    ? hexColorFrom.substring(1)
    : hexColorFrom;
  const colorTo = hexColorTo.startsWith('#')
    ? hexColorTo.substring(1)
    : hexColorTo;

  const r = Math.ceil(
    parseInt(colorFrom.substring(0, 2), 16) * gradientRatio +
      parseInt(colorTo.substring(0, 2), 16) * (1 - gradientRatio),
  );
  const g = Math.ceil(
    parseInt(colorFrom.substring(2, 4), 16) * gradientRatio +
      parseInt(colorTo.substring(2, 4), 16) * (1 - gradientRatio),
  );
  const b = Math.ceil(
    parseInt(colorFrom.substring(4, 6), 16) * gradientRatio +
      parseInt(colorTo.substring(4, 6), 16) * (1 - gradientRatio),
  );

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export default calculateGradientColor;
